import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { auth0Service } from '../Util/Auth0Client';

class PositionsStore {
    data = [];
    isLoading = false;
    error = null;
    positionsType = 'Positions';
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'PositionsStore',
            properties: ['data'],
            storage: window.localStorage
        });
    }
    fetchData = async () => {
        this.setLoading(true);
        this.setError(null);

        try {
            const token = await auth0Service.getToken();
            const header = { Authorization: `Bearer ${token}` };
            const response = await fetch(
                '/api/positions', 
                { 
                    headers: header, 
                    credentials: 'include' 
                }
            ); 
            const positionsData = await response.json();
            runInAction(() => {
                this.setData(positionsData);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    setData(newData) {
        this.data = newData;
    }

    setLoading(loading) {
        this.isLoading = loading;
    }

    setError(error) {
        this.error = error;
    }


    getPositionsType = () => {
        return this.positionsType;
    }
}

export const positionsStore = new PositionsStore();