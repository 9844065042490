import {useState, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, styled, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText, Typography } from '@mui/material';


const CustomTypography = styled(Typography)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
    color: '#000000',
    fontSize: '18px',
  }));

const CustomButton = styled(Button)(({ theme }) => ({
    fontFamily: 'Montserrat, sans-serif',
    marginTop: theme.spacing(3),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    backgroundColor: '#1A5F7A',
    '&:hover': {
      backgroundColor: '#8B0000;',
    },
  }));

const CancelSubscription = ( userSubscription ) => {
    const [ open, setOpen ] = useState(false);
    const [ cancellationDate, setCancellationDate ] = useState(false);
    const [ subscription, setSubscription ] = useState([]);
    const [ isCancelled, setCancelled ] = useState(false);
    const [ planName, setPlanName] = useState([]);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (userSubscription && userSubscription.userSubscription && userSubscription.userSubscription.length > 0) {
            
          const subscription = userSubscription.userSubscription[0].Subscription;
          const trialEnd = subscription.trial_end * 1000;
          const currentTimestamp = Date.now();

          let CancelDate = new Date(subscription.current_term_end * 1000);
    
          if (trialEnd > currentTimestamp) {
            CancelDate = new Date(trialEnd);
          }
    
          const formattedDate = `${CancelDate.getDate().toString().padStart(2, '0')}/${(CancelDate.getMonth() + 1).toString().padStart(2, '0')}/${CancelDate.getFullYear()}`;
          setCancellationDate(formattedDate);
          setSubscription(subscription)
          setPlanName(userSubscription.userSubscription[0].Plan.external_name)

          if(subscription.cancelled_at > 0 ){
            setCancelled(true);
          }
        }
      }, [userSubscription]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const cancelActiveSubscription = async (subscriptionToCancel) => {
        try {
            const token = await getAccessTokenSilently();
            const header = { Authorization: `Bearer ${token}` };
            const body = JSON.stringify({subscription: subscriptionToCancel});

            const subscriptionplans = await fetch(
                '/api/cancel-subscription',
                 { 
                    method: 'POST', 
                    headers: header,
                    credentials: 'include',
                    body: body 
                }
            ) 
        
            if (!subscriptionplans.ok) {
                throw new Error(`HTTP error! Status: ${subscriptionplans.status}`);
            }
        } catch (error) {
            console.log("Something went wrong", error);
        }
        setOpen(false);
        setCancelled(true); 
    }
  
    return (
        <>
            <CustomButton variant="contained" onClick={handleClickOpen} disabled={isCancelled}>
                Cancel Subscription
            </CustomButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', 
                    
                }}>
                    <CustomTypography sx={{fontSize: '24px' }}>
                        {"Cancel you subscription"}
                    </CustomTypography>
                </DialogTitle>
                <DialogContent sx={{textAlign: 'center'}}>
                    <DialogContentText id="alert-dialog-description">
                        <CustomTypography>
                            Do you wish to cancel your <span style={{fontWeight: '600'}}>{planName}</span> subscription? 
                        </CustomTypography>
                        <CustomTypography>
                            If you choose to do so, it'll take effect on <span style={{fontWeight: '600'}}>{cancellationDate}</span>. 
                        </CustomTypography>
                        <CustomTypography>
                            You'll still be able to use Assistant Manager until then. 
                        </CustomTypography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx= {{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',  
                }}>
                    <CustomButton variant="contained" onClick={handleClose} sx ={{'&:hover': {backgroundColor:'#1A5F7A'}}}>Close</CustomButton>
                    <CustomButton variant="contained" onClick={() => cancelActiveSubscription(subscription)} autoFocus sx ={{backgroundColor: '#8B0000;'}}>
                        Cancel subscription
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default CancelSubscription; 