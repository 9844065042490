import React, { useState, useEffect } from "react";

const TermsAndConditions = () => {
  const [ htmlContent, setHtmlContent ] = useState("");

  useEffect(() => {
    fetch("/TermsAndConditions.htm")
      .then((response) => response.text())
      .then((data) => setHtmlContent(data))
      .catch((err) => console.error(err));
  }, []);

  return (
    <div
      className="terms-and-conditions"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};

export default TermsAndConditions;
