import { makeAutoObservable, runInAction, reaction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { impersonationStore } from './ImpersonationStore';
import { auth0Service } from '../Util/Auth0Client';

class UserEntitlementStore {

    entitlementData = {};
    isLoading = false;
    error = null;
    isLoaded = false;       //indicate that entitlements are fetched in store
    pollingInterval = null; // Store the interval ID for polling

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, 
            { 
                name: 'UserEntitlementStore', 
                properties: ['entitlementData', 'isLoaded'], 
                storage: window.localStorage
            }
        );
        this.checksumChecker();
    }

    GetUserEntitlements = async() => {
        this.setLoading(true);
        this.setError(null);

        try {
            const token = await auth0Service.getToken();
            const header = { Authorization: `Bearer ${token}` };
            const response = await fetch('/api/getsubscription', 
                { 
                    headers: header, 
                    credentials: 'include' 
                }
            );
            const userEntitlements = await response.json();
            runInAction(() => {
                this.setData(userEntitlements);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
                this.setIsLoaded(true);
            });
            this.startPolling()
        }

    }

    startPolling = () => {
        this.stopPolling(); // Ensure any existing interval is cleared
    
        this.pollingInterval = setInterval(async () => {
            try {
                const token = await auth0Service.getToken();
                const header = { Authorization: `Bearer ${token}` };
                const response = await fetch(
                    '/api/checkForSubscriptionChange', 
                    { 
                        headers: header, 
                        credentials: 'include' 
                    }
                );
                const newChecksum = await response.json();
                
                // Check if data has changed
                if (newChecksum !== this.entitlementData.Checksum) {
                    await this.GetUserEntitlements();    // Get updated/new entitlements from server
                    // Optionally restart polling here if needed
                    this.startPolling();
                } else {
                }
            } catch (error) {
                console.error('Polling error:', error);
                this.stopPolling(); // Stop polling on error to prevent repeated errors
            }
        }, 5000); 
    }
    
    stopPolling = () => {
        if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
            this.pollingInterval = null;
        }
    }

    stopPolling() {
        if (this.pollingInterval) {
          clearInterval(this.pollingInterval);
        }
      }

    setData(newData) {
        this.entitlementData = newData;
    }

    setLoading(loading) {
        this.isLoading = loading;
    }

    setError(error) {
        this.error = error;
    }

    setIsLoaded(isLoaded) {
        this.isLoaded = isLoaded;
    }

    // checksumChecker updates impersonation dropdownoptions if new entitlement changes
    // are detected
    checksumChecker() {
        reaction(
            () => this.entitlementData.Checksum,
            async () => {
                try {
                    await impersonationStore.loadDropDownOptions();
                } catch (error) {
                    console.error("Error loading dropdown options:", error);
                }
            },
        );
    }

    checkFeatureAccess(keyword) {
        if(keyword === null || keyword === undefined) {
            return true;    // Not a feature and user is entitled to access it
        }

        const featureId = `view-${keyword}`;
        const feature = this.entitlementData.Entitlements?.find(item => item.feature_id === featureId);

        if (feature && feature?.is_enabled) {
            return true; // Feature is enabled
        } else {
            return false; // Feature is not enabled
        }
    }

}

export const userEntitlementStore = new UserEntitlementStore();