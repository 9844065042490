import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { auth0Service } from '../Util/Auth0Client';
import { dashboardStore } from '../State/DashboardStore';
import { squadStore } from './SquadStore';
import { positionsStore } from './PositionsStore';
import { countriesStore } from './CountriesStore';
import { seasonStore } from './SeasonStore';

class ImpersonationStore{
    dropdownOptions = []
    selectedOption = null
    isLoading = false;
    error = null;

    constructor() {
        makeAutoObservable(this);
        makePersistable(this, 
            { 
                name: 'ImpersonationStore', 
                properties: ['dropdownOptions', 'selectedOption'], 
                storage: window.localStorage 
            }
        );
    }

    loadDropDownOptions = async () => {
        this.setLoading(true);
        this.setError(null);
        
        try {
            const token = await auth0Service.getToken();
            const headers = { Authorization: `Bearer ${token}` };
            const [optionsResponse, sessionResponse] = await Promise.all([
                fetch('/api/impersonation', { headers, credentials: 'include' }),
                fetch('/api/getsessionclubid', { headers, credentials: 'include' })
            ]);
      
            const optionsData = await optionsResponse.json();
            const sessionData = await sessionResponse.json();
            runInAction(() => {
                this.setDropdownOptions(optionsData);
                this.setSelectedOption(sessionData);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }


    handleChange = async(event, value) => {
        const newClubId = value.ClubId;
        runInAction(() => {
            this.selectedOption = value;
        });
        try {
            const token = await auth0Service.getToken();
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            const response = await fetch('/api/updatesessionclubid', 
                {
                    credentials: 'include',
                    method: 'POST',
                    headers,
                    credentials: 'include',
                    body: JSON.stringify({ ClubId: newClubId }),
                }
            );
            await seasonStore.loadSeasons()
            await dashboardStore.fetchData()
            await squadStore.fetchData()
            if(positionsStore.data.length === 0) {
                await positionsStore.fetchData()
            }
            if(countriesStore.data.length === 0) {
                await countriesStore.fetchData()
            }
            
        } catch(error) {
            runInAction(() => {
                this.setError(error.message);
            });
        }
    }

    setDropdownOptions(options) {
        this.dropdownOptions = options;
    }

    setSelectedOption(sessionData) {
        if(sessionData) {
            const initialOption = this.dropdownOptions.find(option => parseInt(option.ClubId) === parseInt(sessionData));
            if(initialOption != null) {
                this.selectedOption = initialOption
            }
            else {
                this.selectedOption = null
            }
        }
        else if(this.dropdownOptions > 0) {
            this.selectedOption = this.dropdownOptions[0]
        }
    }

    setLoading(loading) {
        this.isLoading = loading;
    }

    setError(error) {
        this.error = error;
    }
}

export const impersonationStore = new ImpersonationStore();