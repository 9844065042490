import { makeAutoObservable, runInAction } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { auth0Service } from '../Util/Auth0Client';

class SquadStore {
    data = [];
    isLoading = false;
    error = null;
    squadType = 'Squad';
    constructor() {
        makeAutoObservable(this);
        makePersistable(this, {
            name: 'SquadPageStore', 
            properties: ['data'], 
            storage: window.localStorage 
        });
    }
    fetchData = async () => {
        this.setLoading(true);
        this.setError(null);

        try {
            const token = await auth0Service.getToken();
            const header = { Authorization: `Bearer ${token}` };
            const response = await fetch('/api/squadpage', {
                headers: header, 
                credentials: 'include' 
            }); 
            const squadData = await response.json();
            runInAction(() => {
                this.setData(squadData);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });
        } finally {
            runInAction(() => {
                this.setLoading(false);
            });
        }
    }

    setData(selected_Data) {
        this.data = selected_Data;
    }

    updatePlayerPosition = async (playerId, selected_PositionId, selected_CountryId, selected_BirthDate, selected_ContractExpiration, 
        selected_Foot, selected_Height, selected_Weight, selected_NationalityId, clubId) => {

        try {
            const param_PositionId = parseInt(selected_PositionId, 10);
            const param_PlayerId = parseInt(playerId, 10);
            const param_ClubId = parseInt(clubId, 10);
            const param_CountryId = parseInt(selected_CountryId, 10)
            const param_NationalityId = parseInt(selected_NationalityId, 10)
            const param_Height = parseInt(selected_Height)
            const param_Weight = parseInt(selected_Weight)

            const token = await auth0Service.getToken();

            const header = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const response = await fetch('/api/updateplayer', {
                method: 'POST',
                headers: header,
                body: JSON.stringify({ PositionId: param_PositionId, playerId: param_PlayerId, CountryId: param_CountryId, Foot: selected_Foot,
                    Weight: param_Weight, Height: param_Height, BirthDate: selected_BirthDate, ContractExpiration: selected_ContractExpiration, NationalityId: param_NationalityId, ClubId: param_ClubId })
            });
    
            if (!response.ok) {
                throw new Error('Failed to update position');
            }
    
            runInAction(() => {
                this.setLoading(false);
            });
        } catch (error) {
            runInAction(() => {
                this.setError(error.message);
            });

        }
    }

    setLoading(loading) {
        this.isLoading = loading;
    }

    setError(error) {
        this.error = error;
    }

    handleSquadTypeChange = (event, type) => {
        if(type !== null) {
            runInAction(() => {
                this.squadType = type;
            });
        }
    }

    getSquadType = () => {
        return this.squadType;
    }
}

export const squadStore = new SquadStore();